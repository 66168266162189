import axios from 'axios';
import { Message } from 'element-ui';
import store from '@/store/index.js';
import router from '@/router/index.js';
// import { loginOut } from '@/api/account.js';
// let apiUrl = window.location.origin + '/api';
let apiUrl = 'https://stocks.server.lsb200.com/api';

// let apiUrl = 'https://server.oak-aindia.com/api';
axios.defaults.withCredentials = false;


// http request 拦截器
axios.interceptors.request.use(
  config => {
    config.headers = {
      'ba-user-token': store.state.token, // 用户token
      'lang': store.state.lang,
      server: 'true',
      ...config.headers
    }
    config.baseURL = apiUrl;
    return config;
  },
  err => {
    return Promise.reject(err);
  }
);
// http response 拦截器
axios.interceptors.response.use(
  res => {
    if (res.status == 200) {
      if (res.data.code === 0) {
        Message({
          type: 'error',
          message: res.data.msg
        });
        // return res.data;
        return Promise.reject();
      } else if (res.data.code === 1) {
        if (res.data.msg !== 'success') {
          Message({
            type: 'success',
            message: res.data.msg
          });
        }
        return res.data;
      } else if ([302, 409].includes(res.data.code)) {
        Message({
          type: 'error',
          message: res.data.msg
        });
        localStorage.removeItem('stocks');
        router.push('/login');
      }
    } else if (res.status === 500 && res.data.code === 409) {
      Message({
        type: 'error',
        message: res.data.msg
      });
      localStorage.removeItem('stocks');
      router.push('/login');
    } else {
      Message({
        type: 'error',
        message: 'error'
      });
    }
  },
  error => {
    Message({
      type: 'error',
      message: 'error'
    });
    return Promise.reject(error);
  }
);

export default axios;
